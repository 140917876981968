// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/command.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message soulfire.v1.CommandRequest
 */
export interface CommandRequest {
    /**
     * @generated from protobuf field: string instanceId = 1;
     */
    instanceId: string;
    /**
     * @generated from protobuf field: string command = 2;
     */
    command: string;
}
/**
 * @generated from protobuf message soulfire.v1.CommandResponse
 */
export interface CommandResponse {
    /**
     * @generated from protobuf field: int32 code = 1;
     */
    code: number;
}
/**
 * @generated from protobuf message soulfire.v1.CommandCompletionRequest
 */
export interface CommandCompletionRequest {
    /**
     * @generated from protobuf field: string instanceId = 1;
     */
    instanceId: string;
    /**
     * @generated from protobuf field: string command = 2;
     */
    command: string;
}
/**
 * @generated from protobuf message soulfire.v1.CommandCompletionResponse
 */
export interface CommandCompletionResponse {
    /**
     * @generated from protobuf field: repeated string suggestions = 1;
     */
    suggestions: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class CommandRequest$Type extends MessageType<CommandRequest> {
    constructor() {
        super("soulfire.v1.CommandRequest", [
            { no: 1, name: "instanceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "command", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.CommandRequest
 */
export const CommandRequest = new CommandRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommandResponse$Type extends MessageType<CommandResponse> {
    constructor() {
        super("soulfire.v1.CommandResponse", [
            { no: 1, name: "code", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.CommandResponse
 */
export const CommandResponse = new CommandResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommandCompletionRequest$Type extends MessageType<CommandCompletionRequest> {
    constructor() {
        super("soulfire.v1.CommandCompletionRequest", [
            { no: 1, name: "instanceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "command", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.CommandCompletionRequest
 */
export const CommandCompletionRequest = new CommandCompletionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommandCompletionResponse$Type extends MessageType<CommandCompletionResponse> {
    constructor() {
        super("soulfire.v1.CommandCompletionResponse", [
            { no: 1, name: "suggestions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.CommandCompletionResponse
 */
export const CommandCompletionResponse = new CommandCompletionResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.CommandService
 */
export const CommandService = new ServiceType("soulfire.v1.CommandService", [
    { name: "executeCommand", options: {}, I: CommandRequest, O: CommandResponse },
    { name: "tabCompleteCommand", options: {}, I: CommandCompletionRequest, O: CommandCompletionResponse }
]);
