// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/logs.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { LogsService } from "./logs";
import type { LogResponse } from "./logs";
import type { LogRequest } from "./logs";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { PreviousLogResponse } from "./logs";
import type { PreviousLogRequest } from "./logs";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service soulfire.v1.LogsService
 */
export interface ILogsServiceClient {
    /**
     * @generated from protobuf rpc: getPrevious(soulfire.v1.PreviousLogRequest) returns (soulfire.v1.PreviousLogResponse);
     */
    getPrevious(input: PreviousLogRequest, options?: RpcOptions): UnaryCall<PreviousLogRequest, PreviousLogResponse>;
    /**
     * @generated from protobuf rpc: subscribe(soulfire.v1.LogRequest) returns (stream soulfire.v1.LogResponse);
     */
    subscribe(input: LogRequest, options?: RpcOptions): ServerStreamingCall<LogRequest, LogResponse>;
}
/**
 * @generated from protobuf service soulfire.v1.LogsService
 */
export class LogsServiceClient implements ILogsServiceClient, ServiceInfo {
    typeName = LogsService.typeName;
    methods = LogsService.methods;
    options = LogsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: getPrevious(soulfire.v1.PreviousLogRequest) returns (soulfire.v1.PreviousLogResponse);
     */
    getPrevious(input: PreviousLogRequest, options?: RpcOptions): UnaryCall<PreviousLogRequest, PreviousLogResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<PreviousLogRequest, PreviousLogResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: subscribe(soulfire.v1.LogRequest) returns (stream soulfire.v1.LogResponse);
     */
    subscribe(input: LogRequest, options?: RpcOptions): ServerStreamingCall<LogRequest, LogResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<LogRequest, LogResponse>("serverStreaming", this._transport, method, opt, input);
    }
}
