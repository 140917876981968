/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as IndexImport } from './routes/index'
import { Route as DashboardLayoutImport } from './routes/dashboard/_layout'
import { Route as DashboardLayoutIndexImport } from './routes/dashboard/_layout/index'
import { Route as DashboardLayoutInstanceImport } from './routes/dashboard/_layout/$instance'
import { Route as DashboardLayoutInstanceProxiesImport } from './routes/dashboard/_layout/$instance/proxies'
import { Route as DashboardLayoutInstanceControlsImport } from './routes/dashboard/_layout/$instance/controls'
import { Route as DashboardLayoutInstanceAccountsImport } from './routes/dashboard/_layout/$instance/accounts'
import { Route as DashboardLayoutInstanceSettingsNamespaceImport } from './routes/dashboard/_layout/$instance/settings/$namespace'

// Create Virtual Routes

const DashboardImport = createFileRoute('/dashboard')()

// Create/Update Routes

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const DashboardLayoutRoute = DashboardLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardLayoutIndexRoute = DashboardLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardLayoutRoute,
} as any)

const DashboardLayoutInstanceRoute = DashboardLayoutInstanceImport.update({
  id: '/$instance',
  path: '/$instance',
  getParentRoute: () => DashboardLayoutRoute,
} as any)

const DashboardLayoutInstanceProxiesRoute =
  DashboardLayoutInstanceProxiesImport.update({
    id: '/proxies',
    path: '/proxies',
    getParentRoute: () => DashboardLayoutInstanceRoute,
  } as any)

const DashboardLayoutInstanceControlsRoute =
  DashboardLayoutInstanceControlsImport.update({
    id: '/controls',
    path: '/controls',
    getParentRoute: () => DashboardLayoutInstanceRoute,
  } as any)

const DashboardLayoutInstanceAccountsRoute =
  DashboardLayoutInstanceAccountsImport.update({
    id: '/accounts',
    path: '/accounts',
    getParentRoute: () => DashboardLayoutInstanceRoute,
  } as any)

const DashboardLayoutInstanceSettingsNamespaceRoute =
  DashboardLayoutInstanceSettingsNamespaceImport.update({
    id: '/settings/$namespace',
    path: '/settings/$namespace',
    getParentRoute: () => DashboardLayoutInstanceRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/_layout': {
      id: '/dashboard/_layout'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardLayoutImport
      parentRoute: typeof DashboardRoute
    }
    '/dashboard/_layout/$instance': {
      id: '/dashboard/_layout/$instance'
      path: '/$instance'
      fullPath: '/dashboard/$instance'
      preLoaderRoute: typeof DashboardLayoutInstanceImport
      parentRoute: typeof DashboardLayoutImport
    }
    '/dashboard/_layout/': {
      id: '/dashboard/_layout/'
      path: '/'
      fullPath: '/dashboard/'
      preLoaderRoute: typeof DashboardLayoutIndexImport
      parentRoute: typeof DashboardLayoutImport
    }
    '/dashboard/_layout/$instance/accounts': {
      id: '/dashboard/_layout/$instance/accounts'
      path: '/accounts'
      fullPath: '/dashboard/$instance/accounts'
      preLoaderRoute: typeof DashboardLayoutInstanceAccountsImport
      parentRoute: typeof DashboardLayoutInstanceImport
    }
    '/dashboard/_layout/$instance/controls': {
      id: '/dashboard/_layout/$instance/controls'
      path: '/controls'
      fullPath: '/dashboard/$instance/controls'
      preLoaderRoute: typeof DashboardLayoutInstanceControlsImport
      parentRoute: typeof DashboardLayoutInstanceImport
    }
    '/dashboard/_layout/$instance/proxies': {
      id: '/dashboard/_layout/$instance/proxies'
      path: '/proxies'
      fullPath: '/dashboard/$instance/proxies'
      preLoaderRoute: typeof DashboardLayoutInstanceProxiesImport
      parentRoute: typeof DashboardLayoutInstanceImport
    }
    '/dashboard/_layout/$instance/settings/$namespace': {
      id: '/dashboard/_layout/$instance/settings/$namespace'
      path: '/settings/$namespace'
      fullPath: '/dashboard/$instance/settings/$namespace'
      preLoaderRoute: typeof DashboardLayoutInstanceSettingsNamespaceImport
      parentRoute: typeof DashboardLayoutInstanceImport
    }
  }
}

// Create and export the route tree

interface DashboardLayoutInstanceRouteChildren {
  DashboardLayoutInstanceAccountsRoute: typeof DashboardLayoutInstanceAccountsRoute
  DashboardLayoutInstanceControlsRoute: typeof DashboardLayoutInstanceControlsRoute
  DashboardLayoutInstanceProxiesRoute: typeof DashboardLayoutInstanceProxiesRoute
  DashboardLayoutInstanceSettingsNamespaceRoute: typeof DashboardLayoutInstanceSettingsNamespaceRoute
}

const DashboardLayoutInstanceRouteChildren: DashboardLayoutInstanceRouteChildren =
  {
    DashboardLayoutInstanceAccountsRoute: DashboardLayoutInstanceAccountsRoute,
    DashboardLayoutInstanceControlsRoute: DashboardLayoutInstanceControlsRoute,
    DashboardLayoutInstanceProxiesRoute: DashboardLayoutInstanceProxiesRoute,
    DashboardLayoutInstanceSettingsNamespaceRoute:
      DashboardLayoutInstanceSettingsNamespaceRoute,
  }

const DashboardLayoutInstanceRouteWithChildren =
  DashboardLayoutInstanceRoute._addFileChildren(
    DashboardLayoutInstanceRouteChildren,
  )

interface DashboardLayoutRouteChildren {
  DashboardLayoutInstanceRoute: typeof DashboardLayoutInstanceRouteWithChildren
  DashboardLayoutIndexRoute: typeof DashboardLayoutIndexRoute
}

const DashboardLayoutRouteChildren: DashboardLayoutRouteChildren = {
  DashboardLayoutInstanceRoute: DashboardLayoutInstanceRouteWithChildren,
  DashboardLayoutIndexRoute: DashboardLayoutIndexRoute,
}

const DashboardLayoutRouteWithChildren = DashboardLayoutRoute._addFileChildren(
  DashboardLayoutRouteChildren,
)

interface DashboardRouteChildren {
  DashboardLayoutRoute: typeof DashboardLayoutRouteWithChildren
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardLayoutRoute: DashboardLayoutRouteWithChildren,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardLayoutRouteWithChildren
  '/dashboard/$instance': typeof DashboardLayoutInstanceRouteWithChildren
  '/dashboard/': typeof DashboardLayoutIndexRoute
  '/dashboard/$instance/accounts': typeof DashboardLayoutInstanceAccountsRoute
  '/dashboard/$instance/controls': typeof DashboardLayoutInstanceControlsRoute
  '/dashboard/$instance/proxies': typeof DashboardLayoutInstanceProxiesRoute
  '/dashboard/$instance/settings/$namespace': typeof DashboardLayoutInstanceSettingsNamespaceRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardLayoutIndexRoute
  '/dashboard/$instance': typeof DashboardLayoutInstanceRouteWithChildren
  '/dashboard/$instance/accounts': typeof DashboardLayoutInstanceAccountsRoute
  '/dashboard/$instance/controls': typeof DashboardLayoutInstanceControlsRoute
  '/dashboard/$instance/proxies': typeof DashboardLayoutInstanceProxiesRoute
  '/dashboard/$instance/settings/$namespace': typeof DashboardLayoutInstanceSettingsNamespaceRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/dashboard/_layout': typeof DashboardLayoutRouteWithChildren
  '/dashboard/_layout/$instance': typeof DashboardLayoutInstanceRouteWithChildren
  '/dashboard/_layout/': typeof DashboardLayoutIndexRoute
  '/dashboard/_layout/$instance/accounts': typeof DashboardLayoutInstanceAccountsRoute
  '/dashboard/_layout/$instance/controls': typeof DashboardLayoutInstanceControlsRoute
  '/dashboard/_layout/$instance/proxies': typeof DashboardLayoutInstanceProxiesRoute
  '/dashboard/_layout/$instance/settings/$namespace': typeof DashboardLayoutInstanceSettingsNamespaceRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dashboard'
    | '/dashboard/$instance'
    | '/dashboard/'
    | '/dashboard/$instance/accounts'
    | '/dashboard/$instance/controls'
    | '/dashboard/$instance/proxies'
    | '/dashboard/$instance/settings/$namespace'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dashboard'
    | '/dashboard/$instance'
    | '/dashboard/$instance/accounts'
    | '/dashboard/$instance/controls'
    | '/dashboard/$instance/proxies'
    | '/dashboard/$instance/settings/$namespace'
  id:
    | '__root__'
    | '/'
    | '/dashboard'
    | '/dashboard/_layout'
    | '/dashboard/_layout/$instance'
    | '/dashboard/_layout/'
    | '/dashboard/_layout/$instance/accounts'
    | '/dashboard/_layout/$instance/controls'
    | '/dashboard/_layout/$instance/proxies'
    | '/dashboard/_layout/$instance/settings/$namespace'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dashboard"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard",
      "children": [
        "/dashboard/_layout"
      ]
    },
    "/dashboard/_layout": {
      "filePath": "dashboard/_layout.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/_layout/$instance",
        "/dashboard/_layout/"
      ]
    },
    "/dashboard/_layout/$instance": {
      "filePath": "dashboard/_layout/$instance.tsx",
      "parent": "/dashboard/_layout",
      "children": [
        "/dashboard/_layout/$instance/accounts",
        "/dashboard/_layout/$instance/controls",
        "/dashboard/_layout/$instance/proxies",
        "/dashboard/_layout/$instance/settings/$namespace"
      ]
    },
    "/dashboard/_layout/": {
      "filePath": "dashboard/_layout/index.tsx",
      "parent": "/dashboard/_layout"
    },
    "/dashboard/_layout/$instance/accounts": {
      "filePath": "dashboard/_layout/$instance/accounts.tsx",
      "parent": "/dashboard/_layout/$instance"
    },
    "/dashboard/_layout/$instance/controls": {
      "filePath": "dashboard/_layout/$instance/controls.tsx",
      "parent": "/dashboard/_layout/$instance"
    },
    "/dashboard/_layout/$instance/proxies": {
      "filePath": "dashboard/_layout/$instance/proxies.tsx",
      "parent": "/dashboard/_layout/$instance"
    },
    "/dashboard/_layout/$instance/settings/$namespace": {
      "filePath": "dashboard/_layout/$instance/settings/$namespace.tsx",
      "parent": "/dashboard/_layout/$instance"
    }
  }
}
ROUTE_MANIFEST_END */
