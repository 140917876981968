// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/logs.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message soulfire.v1.PreviousLogRequest
 */
export interface PreviousLogRequest {
    /**
     * @generated from protobuf field: string instanceId = 1;
     */
    instanceId: string;
    /**
     * @generated from protobuf field: int32 count = 2;
     */
    count: number;
}
/**
 * @generated from protobuf message soulfire.v1.PreviousLogResponse
 */
export interface PreviousLogResponse {
    /**
     * @generated from protobuf field: repeated string messages = 1;
     */
    messages: string[];
}
/**
 * @generated from protobuf message soulfire.v1.LogRequest
 */
export interface LogRequest {
    /**
     * @generated from protobuf field: string instanceId = 1;
     */
    instanceId: string;
}
/**
 * @generated from protobuf message soulfire.v1.LogResponse
 */
export interface LogResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class PreviousLogRequest$Type extends MessageType<PreviousLogRequest> {
    constructor() {
        super("soulfire.v1.PreviousLogRequest", [
            { no: 1, name: "instanceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.PreviousLogRequest
 */
export const PreviousLogRequest = new PreviousLogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviousLogResponse$Type extends MessageType<PreviousLogResponse> {
    constructor() {
        super("soulfire.v1.PreviousLogResponse", [
            { no: 1, name: "messages", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.PreviousLogResponse
 */
export const PreviousLogResponse = new PreviousLogResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogRequest$Type extends MessageType<LogRequest> {
    constructor() {
        super("soulfire.v1.LogRequest", [
            { no: 1, name: "instanceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogRequest
 */
export const LogRequest = new LogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogResponse$Type extends MessageType<LogResponse> {
    constructor() {
        super("soulfire.v1.LogResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogResponse
 */
export const LogResponse = new LogResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.LogsService
 */
export const LogsService = new ServiceType("soulfire.v1.LogsService", [
    { name: "getPrevious", options: {}, I: PreviousLogRequest, O: PreviousLogResponse },
    { name: "subscribe", serverStreaming: true, options: {}, I: LogRequest, O: LogResponse }
]);
